<template>
  <div>
    <NavTitle title="职位列表"></NavTitle>
    <div class="search">
      <div class="flex items-center mb20">
        <div>职位总数：{{totalNum}}</div>
        <div class="ml20 theme-text pointer" @click="handleExportExcel">导出excel</div>
      </div>
      <el-form size="small" :inline="true">
        <el-form-item label="职位类型">
          <el-select v-model="form.locType" placeholder="请选择线上/线下" @change="handleChange">
            <el-option
              v-for="item in locTypesOption"
              :key="item.type"
              :label="item.text"
              :value="item.type">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="职位状态">
          <el-select v-model="form.statusTypes" placeholder="请选择职位状态" multiple clearable @change="handleChange">
            <el-option
              v-for="item in statusTypeList"
              :key="item.type"
              :label="item.text"
              :value="item.type">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="职位发布时间">
          <el-date-picker
            v-model="form.screenDate"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetimerange"
            range-separator="~"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="handleChange">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="职位城市">
          <el-select v-model="form.cities" placeholder="请选择城市" multiple clearable filterable @change="handleChange">
            <el-option-group
              v-for="group in cityList"
              :key="group.label"
              :label="group.provinceName">
              <el-option
                v-for="item in group.citys"
                :key="item.cityName"
                :label="item.cityName"
                :value="item.cityName">
              </el-option>
            </el-option-group>
          </el-select>
        </el-form-item>
        <el-form-item label="职位类目">
          <div>
            <el-cascader
              v-model="form.categoryIds"
              :options="postCategories"
              :props="{ multiple: true, value: 'id', label: 'name', emitPath: false }"
              :show-all-levels="false"
              placeholder="请选择类目"
              filterable
              clearable
              @change="handleChange">
            </el-cascader>
          </div>
        </el-form-item>
        <el-form-item label="搜索">
          <div class="flex items-center">
            <el-select class="mr10" v-model="form.searchType" placeholder="请选择查询类型">
              <el-option
                v-for="item in searchTypes"
                :key="item.type"
                :label="item.text"
                :value="item.type">
              </el-option>
            </el-select>
            <div class="input-row">
              <el-input class="input" :placeholder="`请输入${form.searchType == 1 ? '岗位ID、标题':'商户ID、账号、名称'}`" size="small" clearable v-model="form.searchKey" @change="handleChange">
                <el-button slot="append" type="primary" icon="el-icon-search" @click="handleChange"></el-button>
              </el-input>
              <!-- <el-button slot="append" type="primary" icon="el-icon-search" size="small" @click="handleSearch"></el-button> -->
            </div>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div class="ml20 mr20" v-loading="loading">
      <PostList :list="postList" @update="getPostList"></PostList>
      <div class="flex justify-center" v-if="postList && postList.length > 0">
        <!-- <Pagination :currentPage="page.current + 1" @next-click="nextPage" @prev-click="prevPage"></Pagination> -->
        <el-pagination
          layout="prev, pager, next, jumper"
          :total="totalNum" :current-page="page.current + 1" :page-size="page.size" @current-change="handlePageChange">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import NavTitle from '@/components/NavTitle'
import Pagination from '@/components/Pagination'
import LoadImage from '@/components/LoadImage'
import PostList from '../components/PostList.vue'
import { systemPostsApi } from '@/api/admin.js'
import { categoriesApi } from '@/api/merchant.js'
import cityList from '@/libs/cityData.js'
import { Workbook } from 'exceljs'
import fileSaver from 'file-saver'
export default {
  components: {
    NavTitle,
    Pagination,
    LoadImage,
    PostList
  },
  data() {
    return {
      locTypesOption: [
        {text: '全部', type: 2},
        {text: '线上', type: 1},
        {text: '线下', type: 0},
      ],
      postList: [],
      statusTypeList: [
        {text: '审核中', type: 0},
        {text: '招聘中', type: 1},
        {text: '暂停招聘', type: 2},
        {text: '结束招聘', type: 3},
        {text: '审核不通过', type: 4},
        {text: '已删除', type: 5},
        {text: '修改审核中', type: 6},
        {text: '修改未通过', type: 7},
        {text: '待发布', type: 8},
        {text: '强制暂停', type: 9},
      ],
      searchTypes: [
        {text: '根据岗位信息查询', type: 1},
        {text: '根据商户信息查询', type: 2}
      ],
      cityList,
      totalNum: 0,
      page: {
        current: 0,
        size: 20
      },
      form: {
        locType: 2,
        statusTypes: [],
        screenDate: '',
        cities: [],
        categoryIds: [],
        searchKey: '',
        searchType: 1
      },
      postCategories: [],
      loading: false,
    }
  },
  created() {
    this.getPostList()
    this.getPostCategories()
  },
  methods: {
    getPostList() {
      const formData = {
        startPage: this.page.current,
        locTypes: this.form.locType == 2 ? [0,1] : [this.form.locType],
        statuses: this.form.statusTypes,
        cities: this.form.cities,
        categoryIds: this.form.categoryIds,
        searchType: this.form.searchType,
        searchKey: this.form.searchKey.trim()
      }
      if(this.form.screenDate && this.form.screenDate.length >= 2) {
        Object.assign(formData, {
          startTime: this.form.screenDate[0],
          endTime: this.form.screenDate[1],
        })
      }
      console.log('formData', formData)
      this.loading = true
      systemPostsApi(formData).then(res => {
        console.log('职位列表',res)
        this.loading = false
        this.totalNum = res.data.totalNum || 0
        const list = res.data.posts || []
        if(list.length != 0 || this.page.current == 0) {
          this.postList = list
        } else {
          this.$tips({message: '没有更多数据了', type:'warning'})
          this.page.current--
        }
      }).catch(err => {
        this.loading = false
        this.$tips({message: err, type: 'error'})
      })
    },
    nextPage() {
      this.page.current++
      this.getPostList()
    },
    prevPage() {
      this.page.current = this.page.current > 0 ? this.page.current - 1 : 0
      this.getPostList()
    },
    handleChange() {
      this.page.current = 0
      this.getPostList()
    },
    handlePageChange(e) {
      this.page.current = e - 1
      this.getPostList()
    },
    handleExportExcel() {
      const list = JSON.parse(JSON.stringify(this.postList))
      const merchantTypeMap = {
        0: '未认证',
        1: '个人认证',
        2: '企业认证',
        3: '企业认证',
        4: '官方直营认证'
      }
      for (const item of list) {
        item.locType = item.locType == 0 ? '线下' : item.locType == 1 ? '线上' : item.locType
        item.merchantType = merchantTypeMap[item.merchantType] || item.merchantType
        item.tags = item.tags.join(',')
        item.welfareTags = item.welfareTags.join(',')
      }
      const workbook = new Workbook()
      const worksheet = workbook.addWorksheet('Sheet1')
      worksheet.columns = [
        {header: 'ID', key: 'postId', width: 20},
        {header: '职位名称', key: 'postTitle', width: 20},
        {header: 'cpc', key: 'cpc', width: 20},
        {header: '浏览量', key: 'glanceNum', width: 20},
        {header: '纬度', key: 'lat', width: 20},
        {header: '经度', key: 'lng', width: 20},
        {header: '位置', key: 'locationDesc', width: 20},
        {header: '商家logo', key: 'merchantLogo', width: 20},
        {header: '商家名称', key: 'merchantName', width: 20},
        {header: '商家ID', key: 'mid', width: 20},
        {header: '价格', key: 'priceDesc', width: 20},
        {header: '状态', key: 'statusDesc', width: 20},
        {header: '标签描述', key: 'welfareTags', width: 20}
      ]
      worksheet.addRows(list)
      workbook.xlsx.writeBuffer().then(buffer => {
        fileSaver(new Blob([buffer], {type: 'application/octet-stream'}), '职位列表.xlsx')
      })

    },
    async getPostCategories() {
      const res1 = await categoriesApi({ natureType: 1 })
      const res2 = await categoriesApi({ natureType: 2 })
      const res3 = await categoriesApi({ natureType: 4 })
      const list1 = this.filterPostCate(res1.data.categoryNodes)
      const list2 = this.filterPostCate(res2.data.categoryNodes)
      const list3 = this.filterPostCate(res3.data.categoryNodes)
      const list = [
        {id: '00001', name:'兼职', children: list1},
        {id: '00002', name:'实习', children: list2},
        {id: '00003', name:'全职', children: list3},
      ]
      console.log('职位分类数据', list, res1)
      // this.listForm.postCate.list = []
      // const list = res.data.categoryNodes || []
      this.postCategories = list
    },
    filterPostCate(list) {
      for (const item of list) {
        const map = {
          1: '兼职',
          2: '实习',
          4: '全职'
        }
        item.name = item.name + '(' + map[item.natureType] + ')'
        if(item.data) item.id = item.data.id
        if(item.children && item.children.length == 0) delete item.children
        if(item.children && item.children.length > 0) {
          this.filterPostCate(item.children)
        }
      }
      return list
    }
  }
}
</script>

<style lang="scss" scoped>
.search {
  background-color: #fff;
  padding: 0 24px;
}
.el-cascader {
  min-width: 400px;
  ::-webkit-scrollbar {
    width: 4px;
    height: 6px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #D9D9D9;
    border-radius: 32px;
  }

  ::-webkit-scrollbar-track {
    background-color: #F6F6F6;
    border-radius: 32px;
  }
  
  ::v-deep .el-cascader__tags {
    max-height: 100px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
.input-row {
  display: flex;
  align-items: center;
  width: 300px;
}
</style>